import { helpers } from 'vuelidate/lib/validators';

const inPast = (minYear, date) => {
  if (date.length === 7) {
    const year = date.replace(/ /g, '').split('/')[1];
    if (year < minYear) { return true; }
  }
  return false;
};

const minValue = (param) => helpers.withParams(
  { type: 'minValue', value: param },
  (value) => !helpers.req(value) || !inPast(param, value),
);

const nombreCompuesto = (value) => {
  if (value === undefined || value === null || value === '') {
    return true;
  }

  return /^(?! )(?!.* {2})[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ ]+$/.test(value);
};

const creditCard = (value) => {
  if (value === undefined || value === null || value === '') {
    return true;
  }

  return /^(?! )(?!.* {2})[0-9 ]+$/.test(value);
};

export {
  nombreCompuesto,
  creditCard,
  minValue,
};
