import {
  mapMutations, mapActions,
} from 'vuex';
import types from '@/types';

const appMixin = {

  methods: {
    ...mapMutations(
      `${types.INVOICE}`, [
        types.SHOW_BUSY,
        types.HIDE_BUSY,
        types.SHOW_SUCCESS,
        types.SHOW_WARNING,
        types.SHOW_ERROR,
      ],
    ),
    // ...mapActions({
    //   [types.REGISTER_ERROR_SENTRY]: `${types.INVOICE}/${types.REGISTER_ERROR_SENTRY}`,
    // }),
    ...mapActions({
      [types.REGISTER_CLICK]: `${types.GA}/${types.REGISTER_CLICK}`,
      [types.REGISTER_EVENT]: `${types.GA}/${types.REGISTER_EVENT}`,
    }),
  },
};

export default appMixin;
