import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{attrs:{"tile":""}},[_c(VAppBar,{attrs:{"color":"primary","dark":"","height":"55"}},[_c(VToolbarTitle,[_vm._v(" "+_vm._s(_vm.$t('Detalle del Pago'))+" ")]),_vm._t("default")],2)],1),_c(VCard,{staticClass:"mt-2",attrs:{"flat":"","outlined":""}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('credit-card',{attrs:{"submit":_vm.submitCreditCard,"paymentError":_vm.paymentError,"serverErrors":_vm.serverErrors,"paymentMethod":_vm.paymentMethod,"merchant_number":_vm.payment.merchant_number},on:{"process-payment":_vm.proccesPayment}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VCard,[_c(VCardText,[_c(VList,{staticClass:"transparent",attrs:{"dense":""}},[_c(VListItem,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('Total facturas'))+":")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.payment.total_advancepayment_amount))+" ")])],1),_c(VListItem,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('Total'))+":")]),_c(VListItemSubtitle,{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.payment.total))+" ")])],1)],1),_c(VBtn,{attrs:{"disabled":_vm.procesing,"block":"","color":"secondary"},on:{"click":_vm.makePayment}},[_vm._v(" "+_vm._s(_vm.$t('Completar el pago'))+" ")])],1)],1)],1)],1)],1)],1)],1),_c('Busy'),_c('Notification')],1)}
var staticRenderFns = []

export { render, staticRenderFns }