<template>
  <div>
    <v-container fluid>
      <v-radio-group v-model="card.id" hide-details>
        <v-radio
          color="primary"
          v-for="card in cardsForSelect" :key="card.name"
          :label="card.name"
          :value="card.id"
        >
          <template v-slot:label>
            <div>
              <span v-if="!card.id">{{ $t(card.name) }}</span>
              <span v-else>**** **** **** {{card.card_number}}</span>
              <strong>{{card.brand}}</strong>
              <span v-if="card.id == paymentMethod"
                class="error--text"
              >
                {{errorsToken.toString()}}
              </span>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
    </v-container>
    <v-slide-y-transition mode="in-out" hide-on-leave>
      <v-container v-if="card.id" fluid>
        <v-btn
          @click="setAddCreditCard"
          outlined
          >
          {{ $t('Add new card') }}
        </v-btn>
      </v-container>
    </v-slide-y-transition>
    <v-slide-y-reverse-transition mode="out-in" hide-on-leave>
      <v-card v-if="!card.id" class="pt-1" color="transparent">
        <v-form @submit.prevent="onSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="card.name"
                  :label="$t('Nombre en la tarjeta')"
                  outlined
                  dense
                  required
                  :error-messages="errorsName"
                  @blur="$v.card.name.$touch()"
                  :hide-details="!$v.card.name.$error"
                  background-color="white"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  :label="$t('Número de la tarjeta')"
                  outlined
                  dense
                  required
                  v-model="card.number"
                  :error-messages="errorsCardNumber"
                  @blur="$v.card.number.$touch()"
                  @focus="removeServerError('number'), removePaymentError('number')"
                  :hide-details="!$v.card.number.$error &&
                    !this.serverErrors.number && !this.transactionDeclined"
                  v-mask="creditCardMask"
                  background-color="white"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  label="MM / YY"
                  outlined
                  dense
                  required
                  v-model="card.expiration"
                  :error-messages="errorsExpiration"
                  @blur="$v.card.expiration.$touch()"
                  :hide-details="!$v.card.expiration.$error &&
                    !hasError(possibleExpirationErrors)"
                  v-mask="expirationMask"
                  background-color="white"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="card.cvc"
                  :label="$t('Codigo Seguridad')"
                  outlined
                  dense
                  required
                  autocomplete="cc-number"
                  :error-messages="errorsCvc"
                  @blur="$v.card.cvc.$touch()"
                  :hide-details="!$v.card.cvc.$error &&
                    !hasError(possibleCvcErrors)"
                  v-mask="cvcMask"
                  background-color="white"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-slide-y-reverse-transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, numeric } from 'vuelidate/lib/validators';
import types from '@/types';
import { getResponseDescription, paymentIsDeclined } from '@/utils/payment';
import { nombreCompuesto, creditCard } from '@/utils/validators';

export default {
  props: [
    'submit',
    'paymentError',
    'serverErrors',
    'paymentMethod',
    'merchant_number',
  ],

  data() {
    return {
      possibleExpirationErrors: [
        'VALIDATION_ERROR:Expiration',
        'VALIDATION_ERROR:ExpirationPassed',
      ],
      possibleCvcErrors: [
        'VALIDATION_ERROR:CVC',
      ],
      expirationMask: '## / ##',
      cvcMask: '####',
    };
  },

  validations: {
    card: {
      name: {
        required,
        nombreCompuesto,
      },
      number: {
        required,
        creditCard,
      },
      expiration: {
        required,
      },
      cvc: {
        required,
        numeric,
      },
    },
  },

  computed: {
    ...mapState(
      `${types.CREDIT_CARD}`, [
        'card',
        'cards',
      ],
    ),
    ...mapGetters(
      `${types.CREDIT_CARD}`, [
        'cardsForSelect',
      ],
    ),
    creditCardMask() {
      if (this.card.number.startsWith(3)) {
        return '#### ###### ######';
      }
      return '#### #### #### ####';
    },
    errorsName() {
      const errors = [];
      if (!this.$v.card.name.$dirty) {
        return errors;
      }
      if (!this.$v.card.name.required) {
        errors.push('Enter your name.');
      }
      if (!this.$v.card.name.nombreCompuesto) {
        errors.push('Enter a valid name.');
      }
      return errors;
    },
    errorsCardNumber() {
      const errors = [];
      if (this.serverErrors.number) {
        errors.push(this.serverErrors.number.toString());
      }
      if (this.transactionDeclined && this.paymentMethod === 0) {
        errors.push(this.declinationReason);
      }
      if (!this.$v.card.number.$dirty) {
        return errors;
      }
      if (!this.$v.card.number.required) {
        errors.push('Enter the card number.');
      }
      if (!this.$v.card.number.creditCard) {
        errors.push('Please enter only numbers.');
      }
      return errors;
    },
    errorsExpiration() {
      const errors = [];
      if (this.possibleExpirationErrors.indexOf(this.paymentError.ErrorDescription) > -1) {
        errors.push('The expiration date is incorrect');
      }
      if (!this.$v.card.expiration.$dirty) {
        return errors;
      }
      if (!this.$v.card.expiration.required) {
        errors.push('Enter the expiration.');
      }
      return errors;
    },
    errorsCvc() {
      const errors = [];
      if (this.possibleCvcErrors.indexOf(this.paymentError.ErrorDescription) > -1) {
        errors.push('The CVC is incorrect');
      }
      if (!this.$v.card.cvc.$dirty) {
        return errors;
      }
      if (!this.$v.card.cvc.required) {
        errors.push('Enter the CVV.');
      }
      if (!this.$v.card.cvc.numeric) {
        errors.push('Please enter only numbers.');
      }
      return errors;
    },
    errorsToken() {
      const errors = [];
      if (this.transactionDeclined && this.paymentMethod !== 0) {
        errors.push(this.declinationReason);
      }
      return errors;
    },
    transactionDeclined() {
      return paymentIsDeclined(this.paymentError.IsoCode);
    },
    declinationReason() {
      return getResponseDescription(this.paymentError);
    },
  },

  watch: {
    submit() {
      this.onSubmit();
    },
  },

  methods: {
    ...mapActions({
      [types.RESET_CREDIT_CARD]: `${types.CREDIT_CARD}/${types.RESET_CREDIT_CARD}`,
    }),
    setAddCreditCard() {
      setTimeout(() => {
        this.card.id = 0;
      }, 100);
    },
    hasError(possibleErrors) {
      return possibleErrors.indexOf(this.paymentError.ErrorDescription) > -1;
    },
    removeServerError(field) {
      delete this.serverErrors[field];
    },
    removePaymentError() {

    },
    onSubmit() {
      if (this.card.id === 0) {
        this.$v.card.$touch();
        if (this.$v.card.$invalid) {
          return;
        }
      }
      this.$emit('process-payment');
    },
  },
  mounted() {
    this[types.RESET_CREDIT_CARD]();
  },
};
</script>
