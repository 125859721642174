<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-card
    tile
  >
    <v-app-bar
      color="primary"
      dark
      height="55"
    >
      <v-toolbar-title>
        {{ $t('Detalle del Pago') }}
      </v-toolbar-title>
      <slot></slot>
    </v-app-bar>
  </v-card>

      <v-card
        flat
        outlined
        class="mt-2"
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <credit-card
                @process-payment="proccesPayment"
                :submit="submitCreditCard"
                :paymentError="paymentError"
                :serverErrors="serverErrors"
                :paymentMethod="paymentMethod"
                :merchant_number="payment.merchant_number"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-card>
                <v-card-text>
                  <v-list dense class="transparent">
                    <!-- <v-list-item>
                      <v-list-item-title>{{ $t('Total facturas:') }}:</v-list-item-title>
                      <v-list-item-subtitle class="text-right">
                        {{payment.total_invoice_amount|currency}}
                      </v-list-item-subtitle>
                    </v-list-item> -->
                    <v-list-item>
                      <v-list-item-title>{{ $t('Total facturas') }}:</v-list-item-title>
                      <v-list-item-subtitle class="text-right">
                        {{payment.total_advancepayment_amount|currency}}
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>{{ $t('Total') }}:</v-list-item-title>
                      <v-list-item-subtitle class="text-right">
                        {{payment.total|currency}}
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                  <v-btn
                    @click="makePayment"
                    :disabled="procesing"
                    block
                    color="secondary"
                    >
                      {{ $t('Completar el pago') }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <Busy />
    <Notification/>
  </v-row>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import {
  getResponseDescription,
  getErrorByErrorDescription, paymentIsDeclined,
} from '@/utils/payment';
import types from '@/types';
import appMixin from '@/mixins/app';
import CreditCard from '../components/CreditCard.vue';
import Busy from '../components/Busy.vue';
import Notification from '../components/Notification.vue';

export default {
  mixins: [appMixin],

  components: {
    CreditCard,
    Busy,
    Notification,
  },

  data() {
    return {
      title: 'Payment Detail',
      procesing: false,
      submitCreditCard: false,
      serverErrors: {},
    };
  },

  computed: {
    // ...mapState(
    //   `${types.APP}`, [
    //     'merchant',
    //   ],
    // ),
    // ...mapState(
    //   `${types.USER}`, [
    //     'clients',
    //     'currentClient',
    //   ],
    // ),
    ...mapState(
      `${types.PAYMENT}`, [
        'payment',
        'paymentError',
        'paymentMethod',
      ],
    ),
    ...mapState(
      `${types.CREDIT_CARD}`, [
        'card',
      ],
    ),
    transactionDeclined() {
      return paymentIsDeclined(this.paymentError.IsoCode);
    },
    declinationReason() {
      if (this.paymentError.IsoCode) {
        return getResponseDescription(this.paymentError);
      }
      if (this.paymentError.detail) {
        return getErrorByErrorDescription(this.paymentError.detail);
      }
      return getErrorByErrorDescription(this.paymentError.ErrorDescription);
    },
  },

  methods: {
    ...mapActions({
      [types.GET_PAYMENT_ATTEMPT]: `${types.PAYMENT}/${types.GET_PAYMENT_ATTEMPT}`,
      [types.MAKE_PAYMENT]: `${types.PAYMENT}/${types.MAKE_PAYMENT}`,
      [types.SAVE_PAYMENT_ATTEMPT]: `${types.PAYMENT}/${types.SAVE_PAYMENT_ATTEMPT}`,
    //   [types.REGISTER_ERROR_SENTRY]: `${types.APP}/${types.REGISTER_ERROR_SENTRY}`,
    }),
    ...mapMutations({
      [types.SHOW_BUSY]: `${types.INVOICE}/${types.SHOW_BUSY}`,
      [types.HIDE_BUSY]: `${types.INVOICE}/${types.HIDE_BUSY}`,
      [types.SHOW_ERROR]: `${types.INVOICE}/${types.SHOW_ERROR}`,
      [types.SET_PAYMENT_ERROR]: `${types.PAYMENT}/${types.SET_PAYMENT_ERROR}`,
    }),
    makePayment() {
      this.submitCreditCard = !this.submitCreditCard;
    },
    retryPayment() {
      this[types.SAVE_PAYMENT_ATTEMPT]()
        .then(() => {
          this.$router.push({ name: 'Pay', params: { id: this.payment.id } });
          this.getPaymentAttempt();
        })
        .catch(() => {
          this[types.SHOW_ERROR]('Error processing payment.');
        })
        .finally(() => {
          this[types.HIDE_BUSY]();
        });
    },
    proccesPayment() {
      this.procesing = true;
      this[types.SHOW_BUSY]('Processing payment...');

      this[types.SET_PAYMENT_ERROR]({ ErrorMessage: '' });
      this[types.MAKE_PAYMENT](this.card)
        .then(() => {
          this.$router.push({ name: 'PaymentDone', params: { id: this.payment.id } });
        })
        .catch((error) => {
          const statusCode = error.response ? error.response.status : null;
          let errorMessage = {};
          switch (statusCode) {
            case 400:
              if (error.response && error.response.data) {
                this.serverErrors = error.response.data;
              }
              if (error.response.data) {
                this[types.SET_PAYMENT_ERROR](error.response.data);
              }
              // Si tenemos el key DateTime, el error vino del procesador de pago
              // https://appsgpc.atlassian.net/browse/APP-115
              if (error.response.data && error.response.data.DateTime) {
                this.retryPayment();
              }
              errorMessage = `Error processing payment, ${this.declinationReason}`;
              break;
            case 500:
              errorMessage = 'Error processing payment, the issue was reported to the service department.';
              //   this[types.REGISTER_ERROR_SENTRY](error);
              break;
            default:
            //   this[types.REGISTER_ERROR_SENTRY](error);
              break;
          }
          this[types.SHOW_ERROR](errorMessage);
          this.procesing = false;
        })
        .finally(() => {
          this[types.HIDE_BUSY]();
        });
    },
    getPaymentAttempt() {
      this.procesing = true;
      this[types.GET_PAYMENT_ATTEMPT](this.$route.params.id)
        .then(() => {
          this.procesing = false;
        });
    },
  },

  mounted() {
    this.getPaymentAttempt();
  },
};
</script>
